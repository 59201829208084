import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/a.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/ai_a.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/ai_staff_bg.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/ai_staff.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/b.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/c.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/apps/migrant/components/images/kefu.png");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/shared/lib/app-router-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/shared/lib/hooks-client-context.shared-runtime.js");
import(/* webpackMode: "eager" */ "/home/ceshi/code/aigxion/node_modules/.pnpm/next@14.1.2_@babel+core@7.24.8_react-dom@18.2.0_react@18.2.0_sass@1.77.7/node_modules/next/dist/shared/lib/server-inserted-html.shared-runtime.js")